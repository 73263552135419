<template lang="pug">
.nav-menu
  .docked-menu
    NuxtLink.menu-item(
        :to="{ name: 'home' }",
        v-bind:class="{ active: route.name == 'home' }"
      )
        span Home

    NuxtLink.menu-item(
      v-if="isEcommerceEnabled",
      :to="{ name: 'ecommerce-display' }",
      v-bind:class="{ active: route.name.startsWith('ecommerce') }"
    )
      span Loja Magenta

  .cart(v-if="isEcommerceEnabled")
    ShoppingCart

  .toggle
    input#nav-menu-toggle.toggle-input(type="checkbox", v-model="navMenuOpen")
    label.toggle-label(for="nav-menu-toggle")
      .toggle-bars
        .toggle-bar
        .toggle-bar
        .toggle-bar

  .close-overlay(
    v-if="navMenuOpen",
    v-bind:onClick="closeNavMenu"
  )

  .floating-menu(:class="{ open: navMenuOpen }")

    .mobile-menu
      NuxtLink.menu-item(
        :to="{ name: 'home' }",
        v-bind:class="{ active: route.name == 'home' }"
      )
        span Home

      NuxtLink.menu-item(
        v-if="isEcommerceEnabled"
        :to="{ name: 'ecommerce-display' }",
        v-bind:class="{ active: route.name == 'ecommerce' }"
      )
        span Loja Magenta

      hr

    .not-logged(v-if="!isLogged")
      NuxtLink.menu-item(
        :to="{ name: 'sign-in' }",
        v-bind:class="{ active: route.name == 'sign-in' }"
      )
        span Acessar minha conta

      NuxtLink.menu-item(
        :to="{ name: 'subscription-register' }",
        v-bind:class="{ active: route.name == 'subscription-register' }"
      )
        span Criar conta

      hr

      NuxtLink.menu-item(
        to="https://atendimento.boxmagenta.com.br/hc/pt-br/categories/21972704470551-Box-Magenta",
        target="_blank"
      )
        span Perguntas frequentes


    .logged(v-if="isLogged")
      NuxtLink.menu-item(
        to="/minha-conta",
        v-bind:class="{ active: route.name == 'dashboard' }"
      )
        span Minha conta

      NuxtLink.menu-item(
        v-if="isEcommerceEnabled"
        to="/minha-conta/compras",
        v-bind:class="{ active: route.name == 'order-list' }"
      )
        span Minhas compras

      NuxtLink.menu-item(
        to="/minha-conta/perfil-de-beleza",
        v-bind:class="{ active: route.name == 'edit-profile' }"
      )
        span Perfil de beleza

      NuxtLink.menu-item(
        :to="{ name: 'edit-payment-profile' }",
        v-bind:class="{ active: route.name == 'edit-payment-profile' }"
      )
        span Dados de pagamento

      NuxtLink.menu-item(
        to="/minha-conta/endereco-de-entrega",
        v-bind:class="{ active: route.name == 'edit-shipping-address' }"
      )
        span Endereço de entrega

      NuxtLink.menu-item(
        :to="{ name: 'reward-list' }",
        v-bind:class="{ active: route.name == 'reward-list' }"
      )
        span Resgatar prêmios

      NuxtLink.menu-item(
        v-if="showMigrateSubscriptionButton",
        to="/minha-conta/migrar-assinatura",
        v-bind:class="{ active: route.path == '/minha-conta/migrar-assinatura' }"
      )
        span Alterar plano de assinatura

      NuxtLink.menu-item(
        v-if="hasAffiliateDashboard",
        :to="{ name: 'affiliate-dashboard' }",
        v-bind:class="{ active: route.name == 'affiliate-dashboard' }"
      )
        span Dashboard de afiliado

      hr

      NuxtLink.menu-item.my-account(
        to="https://atendimento.boxmagenta.com.br/hc/pt-br/",
        target="_blank"
      )
        span Perguntas frequentes

      hr

      //- When outside of dashboard, show "Sair"
      NuxtLink.menu-item.my-account(
        to="/minha-conta/sair"
      )
        span Sair
</template>
<script setup lang="ts">
import { useAuth } from "~/store/auth"
import { useActiveSubscription } from "~/store/subscription/subscription"
import { useCustomerInfo } from "~/store/customer"

const auth = useAuth()
const route = useRoute()
const activeSubscription = useActiveSubscription()
const customerInfo = useCustomerInfo()

const navMenuOpen = ref(false)

const closeNavMenu = () => {
  navMenuOpen.value = false
}

const isLogged = computed(() => auth.isAuthenticated)

const hasAffiliateDashboard = computed(() => customerInfo.affiliateDashboardUrl)
const isEcommerceEnabled = computed(() => useConfig().ecommerceEnabled)

const showMigrateSubscriptionButton = computed(() => {
  const primarySubscription = activeSubscription.subscription

  return (
    primarySubscription &&
    primarySubscription.paymentProfileType == "CREDIT_CARD" &&
    primarySubscription.status == "ACTIVE" &&
    (primarySubscription.planId ==
      "br.com.magenta.vindi.test.basic.monthly.11990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.monthly.11990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.monthly.15990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.monthly.15990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.monthly.16990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.monthly.16990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.quarterly.34470" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.quarterly.34470" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.quarterly.46770" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.quarterly.46770")
  )
})

onMounted(() => {
  watch(
    () => route.path,
    () => {
      navMenuOpen.value = false
    }
  )
})

onDocumentKeydown('Escape', closeNavMenu)
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.nav-menu
  flex-grow: 1
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  gap: 8px

  @include mobile
    justify-content: flex-end

  .docked-menu
    flex-grow: 1
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

    @include mobile
      display: none

    .menu-item
      padding: 16px

  .floating-menu
    position: absolute
    top: 86px
    right: 8px
    width: 260px
    height: auto
    padding: 16px
    z-index: 100
    background-color: white
    box-shadow: 0 15px 45px -10px rgb(0 0 0 / 30%)
    opacity: 0
    pointer-events: none
    transform: translateY(-10px)
    transition: opacity .16s ease, transform .16s ease

    &.open
      opacity: 1
      transform: translateY(0)
      pointer-events: all

    .mobile-menu
      display: none

      @include mobile
        display: block

    .menu-item
      width: 100%

    hr
      border: 0
      height: 1px
      background: #c5c5c5

  .menu-item
    position: relative
    display: block
    padding: 8px
    font-family: "Alata", sans-serif
    font-size: 16px
    text-align: right

    span
      position: relative

      &:before
        content: ""
        display: block
        position: absolute
        top: 0px
        right: -12px
        height: 6px
        width: 6px
        border-radius: 3px

    &:hover
      span:before
        background-color: #c5c5c5

    &.active
      span:before
        background-color: #ef368b

  .close-overlay
    position: fixed
    z-index: 99
    top: 0
    left: 0
    width: 100vw
    height: 100vh

  .toggle
    user-select: none

    .toggle-input
      display: none

    .toggle-label
      position: relative
      display: inline-flex
      align-items: center
      justify-content: center
      z-index: 99
      overflow: visible
      width: 48px
      height: 48px
      cursor: pointer
      opacity: 1
      border: none
      outline: none
      border-radius: 50%
      font-size: 20px
      background-color: transparent

      &:hover
        background-color: #f4f4f4

    .toggle-bars
      position: relative
      width: 20px

    .toggle-bar
      position: absolute
      display: block
      box-sizing: content-box
      top: 0px
      height: 2px
      width: 100%
      content: ''
      transition: transform .16s cubic-bezier(.645, .045, .355, 1), top .16s cubic-bezier(.645, .045, .355, 1) .1s
      border-radius: 10px
      background-color: #000

    .toggle-bar:nth-child(1)
      top: -8px

    .toggle-bar:nth-child(3)
      top: 8px

    .toggle-input:checked ~ label .toggle-bar
      transition: transform .16s cubic-bezier(.645, .045, .355, 1) .16s, top .3s cubic-bezier(.645, .045, .355, 1)

    .toggle-input:checked ~ label .toggle-bar:nth-child(1), .toggle-input:checked ~ label .toggle-bar:nth-child(3)
      top: 0px

    .toggle-input:checked ~ label .toggle-bar:nth-child(1), .toggle-input:checked ~ label .toggle-bar:nth-child(2)
      transform: rotate(45deg)

    .toggle-input:checked ~ label .toggle-bar:nth-child(3)
      transform: rotate(-45deg)
</style>
